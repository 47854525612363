<!--
 * @Author: your name
 * @Date: 2021-04-23 11:35:52
 * @LastEditTime: 2021-05-28 17:09:04
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /lpt_edu_pc/src/views/News.vue
-->
<template>
	<div class="news before">
		<Header :baseInfo="baseInfo" />
		<h4 class="home_title">新闻中心</h4>
		<div class="width_1200 margin_auto news_tab d_flex flex_column justify_content_center" :style="'background:url('+baseImgUrl+'juanzhou_bg.png);background-size:contain;'">
			<div class="tab_title">
				<div @click="newsTabChange(index,item.id)" :class=" index === newsIndex ? 'active' : ''" v-for="(item , index) in tabList" :key="index">{{item.name}}</div>
			</div>
		</div>
		<div class="news_list width_1200 margin_auto">
			<div class="news_items">
				<div class="news_item d_flex justify_content_between" v-for="(item , index) in articleList" :key="index">
					<div class="news_pic" @click="toDetail(item.id)">
						<img :src="item.image" alt="">
					</div>
					<div class="news_content">
						<h5 class="line_one_clamp" @click="toDetail(item.id)">{{item.title}}</h5>
						<span>{{item.updated_at}}</span>
						<p @click="toDetail(item.id)" class="line_three_clamp" v-if="item.description">{{item.description}}</p>
					</div>
				</div>
			</div>
			<div class="pagination" v-if="requestPage.count > 0">
				<el-pagination
					background
					layout="prev, pager, next"
					:page-size="requestPage.pageSize"
					:total="requestPage.count"
					@current-change="change"
					:current-page="requestPage.currentPage"
					>
				</el-pagination>
			</div>
		</div>
		<div class="league_container">
			<div :class="[position === 'fixed' ? 'fixed' : 'relative' , 'league_position']">
				<League :baseInfo="baseInfo"  @openPop="openPop()"/>
			</div>
		</div>
		<div ref="footer_container">
			<Footer :baseInfo="baseInfo" />
		</div>
		<Form v-show="isShow" @closePop="closePop()" />
		<Top :baseInfo="baseInfo" v-show="isTopShow" @openPop="openPop()"/>
	</div>
</template>

<script>
import { config } from '../config';
import mixin from '../utils/mixin';
import * as api from '../utils/api';
export default {
	mixins:[mixin],
	components:{
		"Form":()=>import('../components/Form')
	},
	data () {
		return {
			baseImgUrl:config.baseImgUrl,
			tabList:[],
			articleList:[],
			requestPage:{
				pageSize:5,
				currentPage:1,
				count:0
			},
			newsIndex:0,
			category_id:0,  //类目id
		};
	},

	created(){
		this.getArticle();
	},

   	methods: {
		getArticle(){
			let params = {
				type:2,
				pageSize:this.requestPage.pageSize,
				currentPage:this.requestPage.currentPage
			}
			this.getData(params);
		},
		getData(params){
			api.article(params).then(res=>{
				if(res.status === 0){
					this.tabList = res.data.list;
					this.articleList = res.data.seo_article;
					this.requestPage = res.data.requestPage;
					this.category_id = res.data.list[this.newsIndex].id;
				}
			})
		},
		newsTabChange(index,id){
			this.newsIndex = index;
			this.requestPage.currentPage = 1;
			this.category_id = id;
			let params = {
				type:2,
				pageSize:this.requestPage.pageSize,
				currentPage:this.requestPage.currentPage,
				category_id:id
			}
			this.getData(params)
		},
		change(val){
			let params = {
				type:2,
				pageSize:this.requestPage.pageSize,
				currentPage:val,
				category_id:this.category_id
			}
			this.getData(params);
		},
		toDetail(id){
			this.$router.push({
				path:'/newsDetail',
				query:{
					id:id
				}
			})
		}
	}
}

</script>
<style lang='scss' scoped>
	.news{
		.home_title{
			margin:88px auto 40px;
		}
		.news_tab{
			height:80px;
			.tab_title{
				text-align: left;
				div{
					width:160px;
					height: 50px;
					border-radius: 25px;
					text-align: center;
					line-height: 50px;
					font-size:24px;
					color:#fff;
					display: inline-block;
					margin-left:100px;
					cursor: pointer;
				}
				div.active{
					background: #B09475;
					font-weight: bold;
				}
			}
		}
		.news_list{
			box-shadow: 0px 3px 12px rgba(0, 0, 0, 0.16);
			margin-top:30px;
			padding:0 50px;
			border-radius: 5px;
			margin-bottom:70px;
			.news_items{
				.news_item{
					padding:40px 0;
					border-bottom: 1px solid  #C4C4C4;
					.news_pic{
						cursor: pointer;
						width:240px;
						height:180px;
						img{
							width:100%;
							height:100%;
						}
					}
					.news_content{
						width:800px;
						text-align: left;
						h5{
							font-size:18px;
							font-weight: bold;
							line-height: 30px;
							margin:20px 0 10px 0;
							cursor: pointer;
						}
						span{
							font-size: 14px;
							color:#999;
						}
						p{
							cursor: pointer;
							color:#333;
							font-size:14px;
							margin-top:20px;
							line-height: 22px;
						}
					}
				}	
			}
			.pagination{
				padding:50px 0;
			}
		}	
	}
	::v-deep{
		.el-pagination.is-background .btn-next,
		.el-pagination.is-background .btn-prev,
		.el-pagination.is-background .el-pager li {
			margin: 0 5px;
			background-color: #fff;
			border:1px solid #999;
			color: #999;
			min-width: 30px;
			border-radius: 2px;
		}
		.el-pagination.is-background .el-pager li:not(.disabled).active {
			background-color: #B91829;
			color: #FFF;
			border:1px solid #B91829;
		}
		.el-pagination.is-background .el-pager li:hover{
			color:#B91829;
		}
	}
	
</style>